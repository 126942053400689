
import { defineComponent, ref, inject } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "OrderDetail",
  setup() {
    //   /M/User/OrderDetail/3153
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()
    const freeShippingLimit = ref(0)
    const entity = ref(null);
    const list = ref([]);
    const route = useRoute();
    const id = route.query.id;
    const expendSwitch = ref([true, true, false]);
    axios
      .get("/M/User/OrderDetail/" + id, { params: { validate: true } })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
          list.value = res.data.obj.list;
          biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
          freeShippingLimit.value = res.data.obj.freeShippingLimit;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((e) => {
        Toast({
          type: "error",
          title: e.message || e,
        });
      });
    function switchExpend(index: number, value: boolean) {
      expendSwitch.value[index] = !value;
    }
    return {
        entity,
        dateFormat,
        list,
        expendSwitch,
        switchExpend,
        biaowuPriceOpen,
        freeShippingLimit,
        userInfo
    };
  },
});
